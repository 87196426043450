<template>
  <div
    v-if="!props.isLogin"
    class="flex items-center gap-x-[12px] border-b-[1px] border-base-default cursor-pointer"
    @click="goLogin">
    <userIcon />
    <div class="text-[24px] text-base-black-main font-bold">로그인</div>
  </div>
  <div v-else class="flex flex-col justify-center gap-x-[12px] border-b-[1px] border-base-default">
    <div class="text-[16px] text-base-black-main font-bold leading-[19px]">{{ authStore.user.login_id }}</div>
    <div class="text-[12px] text-base-black-600 leading-[14px]">최근접속 : {{ authStore.user.login_date }}</div>
  </div>
</template>

<script lang="ts" setup>
import userIcon from "@/assets/svg/userIcon_black_24.svg?component";
import { useAuthStore } from "@/stores/auth.js";

const emit = defineEmits(["login-fn"]);
const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false
  }
});
const authStore = useAuthStore();
const goLogin = () => {
  emit("login-fn");
};
</script>

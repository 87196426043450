import { createRouter, createWebHistory } from "@ionic/vue-router";
import routes from "@/router/routes";
import { axiosInstance } from "@/functions/axiosInstance";
import { useAuthStore } from "@/stores/auth";
import _, { isEmpty } from "lodash";
import { useCommonStore } from "@/stores/common";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});
router.beforeEach(async (to, from, next) => {
  useCommonStore().isLoading = true;
  const memberData = await axiosInstance
    .post("/sessions")
    .then((res: any) => res)
    .catch(() => {
      return {};
    });
  const routerToName: any = to.fullPath;

  useCommonStore().isLoading = false;
  if (isEmpty(memberData) && to.meta?.login === true) {
    useAuthStore().$reset();
    if (routerToName && routerToName?.includes("/admin/")) {
      await router.replace("/admin/login");
    } else await router.replace("/login");
  }
  if (!isEmpty(memberData) && _.has(memberData, "phone")) {
    let isAdmin = false;
    useAuthStore().user = memberData;

    if (_.size(memberData.phone) === 0 && !_.includes(["deleteUser", "firstCheck", "privacy", "terms"], to.name)) {
      await router.replace("/firstCheck");
    }
    if (useAuthStore().user["auth"] === "ADMIN" || useAuthStore().user["auth"] === "MASTER") {
      isAdmin = true;
    } else {
      isAdmin = false;
    }
    if (!_.isEmpty(to.meta) && to.meta?.login === false) {
      await router.replace("/");
    }
    if (routerToName && routerToName?.includes("/admin/") && !isAdmin) {
      await router.replace("/");
    }
  }
  next();
});
export default router;

<script lang="ts" setup>
import { useCommonStore } from "@/stores/common.js";

const commonStore = useCommonStore();
</script>

<template>
  <div aria-labelledby="modal-title" aria-modal="false" class="relative z-[60]" role="dialog">
    <div class="fixed inset-0 bg-black/50 transition-opacity"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full justify-center text-center items-center p-0">
        <div class="flex flex-col justify-center gap-[32px]">
          <span class="loader"></span>
          <div v-if="commonStore.loadingMessage !== ''" class="text-white whitespace-pre-line font-bold">
            {{ commonStore.loadingMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

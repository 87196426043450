<template>
  <div class="flex flex-col px-[16px] py-[24px] gap-y-[16px]">
    <div class="flex items-center gap-x-[12px] cursor-pointer" @click="actionButton('modifyUser')">
      <userIcon fill-opacity="0.9" />
      <div class="text-[14px] text-base-black-main">회원정보수정</div>
    </div>
    <div class="flex items-center gap-x-[12px] cursor-pointer" @click="actionButton('modifyPassword')">
      <changePassword />
      <div class="text-[14px] text-base-black-main">비밀번호수정</div>
    </div>
    <div class="flex items-start gap-x-[12px] cursor-pointer" @click="actionButton('reservationStatus')">
      <reservationStatus />
      <div class="flex flex-col">
        <div class="text-[14px] text-base-black-main">예약현황</div>
        <div class="text-[12px] text-base-black-800">(주차등록/현금영수증신청)</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import userIcon from "@/assets/svg/userIcon_black_24.svg?component";
import changePassword from "@/assets/svg/changePassword_24.svg?component";
import reservationStatus from "@/assets/svg/reservationStatus_24.svg?component";

const emit = defineEmits(["action-fn"]);

const actionButton = (type: string) => {
  emit("action-fn", type);
};
</script>

import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: `${
  //   import.meta.env.MODE === "production"
  //     ? "/api" // 프로덕션에서는 /api 경로로 요청
  //     : "http://localhost:8080/api" // 로컬 개발 환경에서는 localhost 사용
  // }`, // 나중에 운영 시 변경
  baseURL: "https://www.futsalbase.com/api",
  withCredentials: true
});
axiosInstance.interceptors.response.use(
  (response: any) => response.data,
  (error: any) => error.response.data
);

export { axiosInstance };

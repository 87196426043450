import { Editor, Quill } from "./Editor.ts";
import BlotFormatter from "quill-blot-formatter/dist/BlotFormatter";
import ImageUploader from "quill-image-uploader";
import { axiosInstance } from "../../functions/axiosInstance";

const ImageBase = Quill.import("formats/image");
let target_type = "public";
let target_id = "";

const setTargetData = ({ type = "public", id = "" }) => {
  target_type = type;
  target_id = id;
};

const ATTRIBUTES = ["alt", "height", "width", "class", "style"];

const globalOptions = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"]
    ],
    blotFormatter: {},
    imageUploader: {
      upload: (file) => {
        return axiosInstance
          .post(
            "/file/quillUpload",
            {
              target_id,
              target_type,
              file: file
            },
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then((res) => {
            if (res.success) {
              return res.result;
            }
            return "";
          })
          .catch(() => {
            return "";
          });
      }
    }
  },
  placeholder: "내용을 입력해주세요.",
  theme: "snow"
};

export class CustomImage extends ImageBase {
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      const copy = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register("formats/image", CustomImage);
Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/blotFormatter", BlotFormatter);
Editor.props.globalOptions.default = () => globalOptions;

export default { Editor, setTargetData };
